import React, {useState, useEffect, useCallback} from 'react'

import Modal from '../../components/Modal'
import PlayerCustomization from '../../components/player/Customization'
import Button from '../../components/Button'
import t from '../../utils/i18n'
import styles from './RoomModal.module.scss'
import {useToggle} from '@umijs/hooks'

type Props = {
  join: (code: string) => void
  create: () => void
  loading: boolean
}

function RoomModal({join, create, loading}: Props) {
  const [code, setCode] = useState<string>('')
  const {state: joining, toggle: toggleJoining} = useToggle()

  const handleJoinClick = useCallback(() => {
    if (code.length !== 5) return
    join(code)
  }, [code, join])

  useEffect(function sendWhenComplete() {
    handleJoinClick()
  }, [code, handleJoinClick])
  
  function handleCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value.trim().toUpperCase())
  }
  
  if (joining) {
    return (
      <Modal onBackButton={() => toggleJoining()} header={<PlayerCustomization />}>
        <React.Fragment>
          <p className={styles.centeredText}>{t('Ask for the room code and paste it here:')}</p>
          <div className={styles.code}>
            <input autoFocus maxLength={5} onChange={handleCodeChange} value={code}/>
          </div>
          <Button onClick={handleJoinClick} loading={loading}>ENTER ROOM</Button>
        </React.Fragment>
      </Modal>
    )
  }
  
  return (
    <Modal header={<PlayerCustomization/>}>
      <div className={styles.container}>
        <div>
          <p>{t('Create a new room, so your friends can join')}</p>
          <Button onClick={create} loading={loading}>CREATE</Button>
        </div>
        <div>
          <p>{t('Join a room one of your friends just created')}</p>
          <Button onClick={() => {toggleJoining()}}>JOIN</Button>
        </div>
      </div>
    </Modal>
  )
}

export default RoomModal