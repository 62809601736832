import {getFromLocalStorage} from '../hooks/useStore'
import {CursorType, CursorsType} from '../types'

export function serverToClient(
  cursors: CursorsType,
  boardSize: {width: number, height: number}
): CursorsType {
  for (const key in cursors) {
    const cursor = cursors[key]
    if (cursor.uuid === getFromLocalStorage('uuid')) {
      delete cursors[key]
      continue
    }
    cursors[key].x = boardSize.width * cursor.x / 100
    cursors[key].y = boardSize.height * cursor.y / 100
  }
  return cursors
}

function invert(value: number, ignore: boolean) {
  return ignore ? value : 100 - value
}

export function clientToServer(
  cursor: CursorType,
  boardSize: {width: number, height: number},
  playerIsUpTop: boolean
): CursorType {
  return {
    ...cursor,
    x: invert(cursor.x * 100 / boardSize.width, !playerIsUpTop),
    y: invert(cursor.y * 100 / boardSize.height, !playerIsUpTop)
  }
}