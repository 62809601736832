import {useState, KeyboardEvent} from 'react'
import {useEventListener} from '@umijs/hooks'

type Key = 'Alt' | 'Control' | 'Command' | 'Shift'
type KeyProp = 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'

const keyMap: {[key in Key]: KeyProp} = {
  Alt: 'altKey',
  Control: 'ctrlKey',
  Command: 'metaKey',
  Shift: 'shiftKey'
}

function useHoldingMetaKey(key: Key): boolean {
  const [holding, setHolding] = useState<boolean>(false)
  const keyProp = keyMap[key]
  
  function handler(event: KeyboardEvent) {
    if (event.key !== key) return
    if (event[keyProp] === holding) return
    setHolding(event[keyProp])
  }
  
  useEventListener('keydown', handler)
  useEventListener('keyup', handler)

  return holding
}

export default useHoldingMetaKey