import React, {ReactElement} from 'react'
import classnames from 'classnames'
import {useHover} from '@umijs/hooks'
import t from '../../utils/i18n'
import styles from './Tooltip.module.scss'

type Props = {
  title: string
  className?: string
  children: ReactElement
  disabled?: boolean
  persistent?: boolean
  block?: boolean
}

function Tooltip({title, className, children, disabled, persistent, block}: Props) {
  const [hovered, ref] = useHover<HTMLDivElement>()
  
  return (
    <div ref={ref} className={classnames(styles.container, {[styles.block]: block})}>
      {!disabled && (persistent || hovered) && <div className={classnames(styles.tooltip, className)}>{t(title)}</div>}
      {children}
    </div>
  )
}

export default Tooltip