import React, {useEffect} from 'react'
import PlayerStatus from './PlayerStatus'
import {usePrevious} from '@umijs/hooks'
import useStore from '../../hooks/useStore'
import t from '../../utils/i18n'
import styles from './LobbyModal.module.scss'
import type {PlayerType} from '../../types'

type Props = {
  players: PlayerType[]
  me: PlayerType
  started: boolean
}

function Players({players, me, started}: Props) {
  const setState = useStore(state => state.setState)
  const wasOwner = usePrevious(me.isOwner)

  useEffect(function notifyWhenYouGotPromoted() {
    if (wasOwner === undefined || !me.isOwner || me.isOwner === wasOwner) return
    setState({toastMessage: 'You are now the room owner'})
  }, [me, players, setState, wasOwner])

  if (!players.length) {
    return (
      <div>
        <span className={styles.loader}/>
        <p className={styles.message}>{t('Wait for the room owner to let you in')}</p>
      </div>
    )
  }
  return (
    <ul className={styles.playerList}>
      {players.map(player => (
        <PlayerStatus key={player.uuid} {...player} imOwner={me.isOwner} started={started}/>
      ))}
    </ul>
  )
}

export default Players
