import React, {useMemo} from 'react'
import classnames from 'classnames'
import Players from './Players'
import CardPopup from '../components/Card/Popup'
import CardHeld from '../components/Card/Held'
import TutorialModal from '../Modals/TutorialModal'
import Cursor from '../components/Cursor'
import useCursors from '../hooks/useCursors'
import {isPlayerUpTop} from '../utils'
import styles from './Board.module.scss'
import type {PlayerType, TurnType} from '../types'
import useStore from '../hooks/useStore'

type Props = {
  players: PlayerType[]
  me: PlayerType
  turn: TurnType
  boardRef: React.RefObject<HTMLDivElement>
  lastWinner?: string
}

function Board({players, me, turn, boardRef, lastWinner}: Props) {
  const skipZoomTip = useStore(state => state.skipZoomTip)
  const playerIsUpTop = useMemo(() => isPlayerUpTop(players), [players])
  const cursors = useCursors(!!me && me.accepted, playerIsUpTop)

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.board, {[styles.flipped]: playerIsUpTop})}
        ref={boardRef}>
        <Players
          players={players}
          turn={turn} boardFlipped={playerIsUpTop}
          cursors={cursors}
          lastWinner={lastWinner}
          skipZoomTip={skipZoomTip}/>
        {Object.values(cursors).map(cursor => <Cursor key={cursor.uuid} {...cursor}/>)}
      </div>
      <CardPopup/>
      <CardHeld/>
      {me.accepted && <TutorialModal/>}
    </div>
  )
}

export default Board