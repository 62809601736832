import {languages} from "./constants"

export enum CardTypeEnum {
  Perk = 'perks',
  Flag = 'flags'
}

export enum CardSlotEnum {
  Perk1 = 'placedPerk1',
  Perk2 = 'placedPerk2',
  Flag = 'placedFlag'
}

export enum TurnTypeEnum {
  Perks = 'perks',
  Flags = 'flags',
  Pick = 'pick'
}

export type CardType = {
  id: number
  type: CardTypeEnum
  text: string
}

export type PlayerType = {
  uuid: string
  connected: boolean
  nickname: string
  color: string
  accepted: boolean
  isOwner: boolean
  isMaster: boolean
  cards: CardType[]
  score: number
  placedPerk1?: CardType
  placedPerk2?: CardType
  placedFlag?: CardType
  me?: boolean
}

export type TurnType = {
  number: number
  player: string
  target: string
  type: TurnTypeEnum
  round: number
}

export type GameType = {
  ended: boolean
  turn: TurnType
  flags: number[]
  perks: number[]
  lastWinner: string
}

export type RoomType = {
  id: string
  code: string
  me: PlayerType
  players: PlayerType[]
  game: GameType
}

export type HoldingCard = {
  index: number
  type: CardTypeEnum
  text: string
  id: number
} | null

export type CursorType = {
  uuid: string
  nickname: string
  color: string
  grabbing: boolean
  holdingCard: HoldingCard
  x: number
  y: number
}

export type CursorsType = {
  [uuid: string]: CursorType
}

export type LanguagesKeys = keyof typeof languages