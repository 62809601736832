import React from 'react'
import classnames from 'classnames'
import useStore from '../../hooks/useStore'
import styles from './Card.module.scss'
import {useMouse} from '@umijs/hooks'
import useHoldingMetaKey from '../../hooks/useHoldingMetaKey'
import useHoldingKey from '../../hooks/useHoldingKey'
import {CardTypeEnum} from '../../types'

function Popup() {
  const data = useStore(state => state.cardPopup)
  const setState = useStore(state => state.setState)
  const holdingAlt = useHoldingMetaKey('Control')
  const holdingSpace = useHoldingKey(32)
  const cursor = useMouse()
  const shown = data && (holdingAlt || holdingSpace)

  if (shown) {
    setState({skipZoomTip: true})
  }

  return (
    <div
      className={classnames(styles.popup, {
        [styles.shown]: shown,
        [styles.flags]: data?.type === CardTypeEnum.Flag
      })}
      style={{top: cursor.clientY || 0, left: cursor.clientX || 0}}>
      {data?.text.split('\\n').map((line, index) => <span key={index} className={styles.line}>{line}</span>)}
    </div>
  )
}

export default Popup