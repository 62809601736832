import React from 'react'

type Props = {
  color: string
}

function HandIcon({color}: Props) {
  return (
    <svg viewBox="0 0 100 100" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="m12.371134,40.824742l3.917526,-4.226804l10.824742,-0.412371l0,-5.56701l4.845361,-4.329897l30,-0.206186l5.463918,5.051546l3.608247,0l6.494845,4.536082l-0.618557,20.618557l-5.360825,5.154639l0,4.639175l-3.917526,4.123711l-0.412371,10.515464l-6.185567,-0.206186l-3.402062,-4.020619l-7.835052,4.845361l-15.051546,-0.309278l-7.835052,-10.515464l-5.979381,-4.536082l-4.845361,-7.216495l-3.505155,-10.618557l-0.206186,-7.319588z"/>
      <rect height="5" width="5" y="53.607595" x="74.367089"/><rect height="5" width="5" y="43.607595" x="74.367089"/>
      <rect height="5" width="5" y="48.607595" x="74.367089"/><rect height="5" width="5" y="38.607595" x="74.367089"/>
      <rect height="5" width="5" y="33.607595" x="74.367089"/><rect height="5" width="5" y="58.607595" x="69.367089"/>
      <rect height="5" width="5" y="63.607595" x="69.367089"/><rect height="5" width="5" y="28.607595" x="69.367089"/>
      <rect height="5" width="5" y="73.607595" x="64.367089"/><rect height="5" width="5" y="78.607595" x="64.367089"/>
      <rect height="5" width="5" y="68.607595" x="64.367089"/><rect height="5" width="5" y="28.607595" x="64.367089"/>
      <rect height="5" width="5" y="33.607595" x="64.367089"/><rect height="5" width="5" y="78.607595" x="59.367089"/>
      <rect height="5" width="5" y="23.607595" x="59.367089"/><rect height="5" width="5" y="73.607595" x="54.367089"/>
      <rect height="5" width="5" y="28.607595" x="54.367089"/><rect height="5" width="5" y="23.607595" x="54.367089"/>
      <rect height="5" width="5" y="33.607595" x="54.367089"/><rect height="5" width="5" y="78.607595" x="49.367089"/>
      <rect height="5" width="5" y="23.607595" x="49.367089"/><rect height="5" width="5" y="78.607595" x="44.367089"/>
      <rect height="5" width="5" y="23.607595" x="44.367089"/><rect height="5" width="5" y="78.607595" x="39.367089"/>
      <rect height="5" width="5" y="28.607595" x="39.367089"/><rect height="5" width="5" y="23.607595" x="39.367089"/>
      <rect height="5" width="5" y="33.607595" x="39.367089"/><rect height="5" width="5" y="78.607595" x="34.367089"/>
      <rect height="5" width="5" y="23.607595" x="34.367089"/><rect height="5" width="5" y="73.607595" x="29.367089"/>
      <rect height="5" width="5" y="78.607595" x="29.367089"/><rect height="5" width="5" y="23.607595" x="29.367089"/>
      <rect height="5" width="5" y="68.607595" x="24.367089"/><rect height="5" width="5" y="43.607595" x="24.367089"/>
      <rect height="5" width="5" y="28.607595" x="24.367089"/><rect height="5" width="5" y="38.607595" x="24.367089"/>
      <rect height="5" width="5" y="33.607595" x="24.367089"/><rect height="5" width="5" y="63.607595" x="19.367089"/>
      <rect height="5" width="5" y="33.607595" x="19.367089"/><rect height="5" width="5" y="58.607595" x="14.367089"/>
      <rect height="5" width="5" y="53.607595" x="14.367089"/><rect height="5" width="5" y="33.607595" x="14.367089"/>
      <rect height="5" width="5" y="43.607595" x="9.367089"/><rect height="5" width="5" y="48.607595" x="9.367089"/>
      <rect height="5" width="5" y="38.607595" x="9.367089"/>
    </svg>
  )
}

export default HandIcon