import React, { ReactNode } from 'react'
import classnames from 'classnames'

import styles from './Drawer.module.scss'

type Props = {
  children: ReactNode
  header?: ReactNode
}

function Modal({children, header}: Props) {
  return (
    <div className={classnames(styles.container, {[styles.withHeader]: !!header})}>
      {!!header &&
        <div className={styles.header}>
          {header}
        </div>
      }
      {children}
    </div>
  )
}

export default Modal