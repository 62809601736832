import React from 'react'
import useStore from '../../hooks/useStore'
import styles from './Card.module.scss'
import {useMouse} from '@umijs/hooks'
import Card from '.'

function Held() {
  const data = useStore(state => state.holdingCard)
  const cursor = useMouse()
  
  if (data === null) return null
  const {text, type, id} = data

  return (
    <div
      className={styles.cardHeld}
      style={{top: cursor.clientY || 0, left: cursor.clientX || 0}}>
      <Card id={id} text={text} type={type} held/>
    </div>
  )
}

export default Held