import React from 'react'
import Box from './Box'
import {useMutation} from '@apollo/react-hooks'
import useStore from '../../../hooks/useStore'
import useThrottle from '../../../hooks/useThrottle'
import type {PlayerType, TurnType, CursorType} from '../../../types'
import {PICK_WINNER} from '../../../graphql'
import {gaEvent} from '../../../utils'

type Props = PlayerType & {
  turn: TurnType,
  boardFlipped: boolean
  upper?: boolean
  cursor?: CursorType
  lastWinner?: string
}

function PlayerBoxContainer(props: Props) {
  const roomId = useStore(state => state.roomId)
  const holdingCard = useStore(state => state.holdingCard)
  const [pickWinner, {loading}] = useMutation(PICK_WINNER)

  const handlePickAsWinner = useThrottle(() => {
    if (loading) return
    pickWinner({variables: {roomId, playerId: props.uuid}})
    gaEvent('game_pick_winner')
  }, 5000)

  return (
    <Box {...props} pickAsWinner={handlePickAsWinner} holdingCard={holdingCard}/>
  )
}

export default PlayerBoxContainer