import React, {useState} from 'react'
import classnames from 'classnames'
import {languages} from '../../../../constants'
import {LanguagesKeys} from '../../../../types'
import useStore from '../../../../hooks/useStore'
import styles from './Language.module.scss'
import {gaEvent} from '../../../../utils'

function Language() {
  const lang = useStore(state => state.lang)
  const setState = useStore(state => state.setState)
  const [isOpen, setIsOpen] = useState(false)
  
  function toggle() {
    setIsOpen(!isOpen)
  }
  
  function pick(newLang: LanguagesKeys) {
    gaEvent('player_change_language', {from: lang, to: newLang})
    setState({lang: newLang})
    toggle()
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.bubble}
        onClick={toggle}>
        {languages[lang]}
      </div>
      {isOpen && (
        <React.Fragment>
          <div className={styles.backdrop} onClick={toggle}/>
          <div className={styles.popup}>
            {Object.entries(languages).map(([value, label]) => (
              <div
                key={value}
                className={classnames(styles.bubble, styles.inList)}
                onClick={() => pick(value as LanguagesKeys)}>
                {label}
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default Language