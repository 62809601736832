import create from 'zustand'
import {nanoid} from 'nanoid'
import {getRandomArrayItem, getLangFromBrowser} from '../utils'
import {nicknames, colors, languages} from '../constants'
import {CardType, HoldingCard, LanguagesKeys} from '../types'

const LS_PREFIX = 'red-flags'
const LS_UUID_KEY = `${LS_PREFIX}_uuid`

export function getFromLocalStorage(key: string, admittedValues?: string[]) {
  const value = localStorage[`${LS_PREFIX}_${key}`]
  if (admittedValues && !admittedValues.includes(value)) {
    return null
  }
  return value
}

export type AppState = {
  setState: (newState: Partial<AppState>) => void
  nickname: string
  color: string
  roomId: string
  lang: LanguagesKeys
  boardSize: {
    width: number
    height: number
  },
  holdingCard: HoldingCard
  cardBeingPlaced: HoldingCard
  cardReleasedAt: {
    x: number
    y: number
  }
  cardPopup: CardType | null
  toastMessage: string
  perksPlaced: number
  flagsPlaced: number
  skipTutorial: boolean
  skipZoomTip: boolean
  skipCustomizationTooltip: boolean
}

const persistentProps = ['nickname', 'color', 'lang', 'roomId', 'perksPlaced', 'flagsPlaced', 'skipTutorial', 'skipZoomTip', 'skipCustomizationTooltip']

function saveToLocalStorage(newState: Partial<AppState>) {
  Object.entries(newState).forEach(([key, value]) => {
    const prefixedKey = `${LS_PREFIX}_${key}`
    if (!persistentProps.includes(key) || value === localStorage[prefixedKey]) return
    localStorage[prefixedKey] = value
  })
}

export const [useStore, store] = create<AppState>(set => {
  if (!localStorage[LS_UUID_KEY]) {
    localStorage[LS_UUID_KEY] = nanoid()
  }

  const state = {
    setState: (newState: Partial<AppState>) => set(state => {
      saveToLocalStorage(newState)
      return {...state, ...newState}
    }),
    nickname: getFromLocalStorage('nickname') || getRandomArrayItem(nicknames),
    color: getFromLocalStorage('color') || getRandomArrayItem(colors),
    lang: getFromLocalStorage('lang', Object.keys(languages)) || getLangFromBrowser() || 'en-us',
    roomId: getFromLocalStorage('roomId') || '',
    boardSize: {width: 0, height: 0},
    cardPopup: null,
    holdingCard: null,
    cardBeingPlaced: null,
    cardReleasedAt: {x: 0, y: 0},
    toastMessage: '',
    perksPlaced: parseInt(getFromLocalStorage('perksPlaced')) || 0,
    flagsPlaced: parseInt(getFromLocalStorage('flagsPlaced')) || 0,
    skipTutorial: JSON.parse(getFromLocalStorage('skipTutorial') || false),
    skipZoomTip: JSON.parse(getFromLocalStorage('skipZoomTip') || false),
    skipCustomizationTooltip: JSON.parse(getFromLocalStorage('skipCustomizationTooltip') || false)
  }
  saveToLocalStorage(state)

  persistentProps.forEach(key => {
    const value = localStorage[key]
    if (value === undefined) return
    // @ts-ignore
    state[key] = value
  })

  return state
})

export default useStore 