import React, {useState} from 'react'
import classnames from 'classnames'
import {colors} from '../../../../constants'
import useStore from '../../../../hooks/useStore'
import styles from './ColorPicker.module.scss'

type Props = {
  changeColor: (color: string) => void
}

function ColorPicker({changeColor}: Props) {
  const color = useStore(state => state.color)
  const [isOpen, setIsOpen] = useState(false)
  
  function toggle() {
    setIsOpen(!isOpen)
  }

  function pick(color: string) {
    changeColor(color)
    toggle()
  }

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.dot, styles.active)}
        style={{backgroundColor: color}}
        onClick={toggle}/>
      {isOpen && (
        <React.Fragment>
          <div className={styles.backdrop} onClick={toggle}/>
          <div className={styles.popup}>
            {colors.map(color => (
              <div
                key={color}
                className={styles.dot}
                style={{backgroundColor: color}}
                onClick={() => pick(color)}/>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ColorPicker