import React, {useCallback, useEffect, useMemo, useState} from 'react'
import DropZone from './DropZone'
import {useMutation} from '@apollo/react-hooks';
import {usePrevious} from '@umijs/hooks'
import useStore from '../../../../hooks/useStore';
import {CardType, CardSlotEnum, CardTypeEnum, HoldingCard} from '../../../../types';
import {PLAY_CARD} from '../../../../graphql'

type Props = {
  slot: CardSlotEnum
  disabled?: boolean
  card?: CardType
  targetId: string
  upper?: boolean
  myTurn?: boolean
}

function DropZoneContainer({slot, disabled, card, targetId, upper, myTurn}: Props) {
  const roomId = useStore(state => state.roomId)
  const holdingCard = useStore(state => state.holdingCard)
  const prevHeldCard = usePrevious(holdingCard)
  const setState = useStore(state => state.setState)
  const perksPlaced = useStore(state => state.perksPlaced)
  const flagsPlaced = useStore(state => state.flagsPlaced)
  const [playCard] = useMutation(PLAY_CARD)
  const [cardBeingPlaced, setCardBeingPlaced] = useState<HoldingCard>()
  
  const holdingTheRightCard = useMemo(() => {
    if (!holdingCard) return false
    if (holdingCard.type === CardTypeEnum.Perk && slot !== CardSlotEnum.Flag) return true
    if (holdingCard.type === CardTypeEnum.Flag && slot === CardSlotEnum.Flag) return true
    return false
  }, [holdingCard, slot])
  
  useEffect(function cleanCardsBeingPlaced() {
    setCardBeingPlaced(null)
    setState({cardBeingPlaced: null})
  }, [myTurn, setState])

  const handleDrop = useCallback(() => {
    if (!myTurn || !prevHeldCard) return
    if (prevHeldCard.type === CardTypeEnum.Perk && slot === CardSlotEnum.Flag) {
      return setState({toastMessage: 'You need to place a red card here'})
    }
    if (prevHeldCard.type === CardTypeEnum.Flag && slot !== CardSlotEnum.Flag) {
      return setState({toastMessage: 'You need to place a white card here'})
    }
    if (disabled) return
    setCardBeingPlaced(prevHeldCard)
    setState({
      cardBeingPlaced: prevHeldCard,
      ...(prevHeldCard.type === CardTypeEnum.Perk ? {perksPlaced: perksPlaced + 1} : {flagsPlaced: flagsPlaced + 1})
    })
    playCard({variables: {roomId, targetId, slot, card: prevHeldCard.id}})
  }, [slot, prevHeldCard, playCard, roomId, setState, targetId, disabled, flagsPlaced, perksPlaced, myTurn])
  
  return (
    <DropZone
      card={card || cardBeingPlaced}
      disabled={disabled}
      holdingCard={holdingTheRightCard}
      handleDrop={handleDrop}
      upper={upper}
      slot={slot}/>
  )
}

export default DropZoneContainer