import React from 'react'
import {useSubscription} from '@apollo/react-hooks'

import Board from './Board'
import Modals from './Modals'
import FixedButtons from './components/FixedButtons'
import Toast from './components/Toast'
import useStore from './hooks/useStore'

import {ON_ROOM_STATE_CHANGE} from './graphql'
import {RoomType} from './types'

function App() {
  const roomId = useStore(state => state.roomId)
  const lang = useStore(state => state.lang)
  const setState = useStore(state => state.setState)

  const {data, error} = useSubscription<{roomStateChanged?: RoomType}>(
    ON_ROOM_STATE_CHANGE,
    {skip: !roomId, variables: {id: roomId, lang}}
  )

  if (error) {
    if (error.message.includes('Player not in room')) {
      setState({
        roomId: '',
        toastMessage: 'You left/got kicked of the room'
      })
    }
    if (error.message.includes('Room not found')) {
      setState({
        roomId: '',
        toastMessage: 'The room does no longer exist'
      })
    }
    return null
  }

  return (
    <React.Fragment>
      <Board
        me={data?.roomStateChanged?.me}
        players={data?.roomStateChanged?.players}
        turn={data?.roomStateChanged?.game?.turn}
        lastWinner={data?.roomStateChanged?.game?.lastWinner}/>
      <Modals
        me={data?.roomStateChanged?.me}
        players={data?.roomStateChanged?.players}
        code={data?.roomStateChanged?.code}
        game={data?.roomStateChanged?.game}/>
      <FixedButtons
        playing={!!data?.roomStateChanged?.me && !!data?.roomStateChanged?.game?.turn}/>
      <Toast/>
    </React.Fragment>
  )
}

export default App