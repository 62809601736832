import React from 'react'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import t from '../../utils/i18n'
import styles from './TutorialModal.module.scss'
import useStore from '../../hooks/useStore'

function TutorialModal() {
  const setState = useStore(state => state.setState)
  const skipTutorial = useStore(state => state.skipTutorial)
  
  function closeTutorial() {
    setState({skipTutorial: true})
  }
  
  if (skipTutorial) return null
  
  return (
    <Modal header={<div className={styles.title}>{t('How to play')}</div>}>
      <div className={styles.container}>
        <ul className={styles.steps}>
          <li className={styles.step}>
            <span className={styles.number}>1</span>
            <div className={styles.image}/>
            <p className={styles.text}>
              <span>{t('We are all introducing one lucky friend to what we believe is their perfect match! ')}</span>
              <span role="img" aria-label="angel">😇</span>
            </p>
          </li>
          <li className={styles.step}>
            <span className={styles.number}>2</span>
            <div className={styles.image}/>
            <p className={styles.text}>
              <span>{t("When it's your turn, choose 2 perks you believe they would like in a person they date ")}</span>
              <span role="img" aria-label="proud">😌</span>
            </p>
          </li>
          <li className={styles.step}>
            <span className={styles.number}>3</span>
            <div className={styles.image}/>
            <p className={styles.text}>
              <span>{t("But it doesn't end there, after all perks are placed, you will have the chance to ")}</span>
              <span role="img" aria-label="fire">🔥</span>
              <span>{t(' your opponents adding a red flag to their applicant')}</span>
            </p>
          </li>
          <li className={styles.step}>
            <span className={styles.number}>4</span>
            <div className={styles.image}/>
            <p className={styles.text}>
              <span>{t('Then your friend has to pick the best (or least worst ')}</span>
              <span role="img" aria-label="grimacing">😬</span>
              <span>{t(') applicant, if yours gets picked, you get a point!')}</span>
            </p>
          </li>
        </ul>
        <div className={styles.bottom}>
          <p>{t("And that's it! have fun and play as many rounds as you want!")}</p>
          <Button onClick={closeTutorial} narrow>GOT IT!</Button>
        </div>
      </div>
    </Modal>
  )
}

export default TutorialModal