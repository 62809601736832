import {useState, useEffect, useRef} from 'react'
import io from 'socket.io-client'
import {useEventListener} from '@umijs/hooks'
import useStore, {getFromLocalStorage} from '../hooks/useStore'
import useThrottle from './useThrottle'
import {serverToClient, clientToServer} from '../utils/cursor'
import type {CursorsType} from '../types'

function useCursors(accepted: boolean, playerIsUpTop: boolean) {
  const nickname = useStore(state => state.nickname)
  const color = useStore(state => state.color)
  const roomId = useStore(state => state.roomId)
  const boardSize = useStore(state => state.boardSize)
  const holdingCard = useStore(state => state.holdingCard)
  const roomCursors = useRef<SocketIOClient.Socket>()
  const [cursors, setCursors] = useState<CursorsType>({})

  useEffect(function subscribeToRoomCursors() {
    if (!roomId || !accepted) return
    
    roomCursors.current = io.connect(process.env.REACT_APP_SOCKETIO_ENDPOINT || 'http://localhost:8080', {
      query: `room=${roomId}`
    })
    
    roomCursors.current.on('move', (cursors: CursorsType) => {
      setCursors(serverToClient(cursors, boardSize))
    })
    
    return () => {
      if (!roomCursors.current) return
      roomCursors.current.disconnect()
    }
  }, [roomId, accepted, boardSize])
  
  const sendCursorPosition = useThrottle(event => {
    if (!roomCursors.current) return
    roomCursors.current.emit('moved', clientToServer({
      uuid: getFromLocalStorage('uuid'),
      nickname,
      color,
      grabbing: event.which === 1,
      holdingCard: holdingCard ? {
        index: holdingCard.index,
        type: holdingCard.type,
        text: "HIDDEN_CARD",
        id: -1
      } : null,
      x: event.clientX - ((window.innerWidth - boardSize.width) / 2),
      y: event.clientY - ((window.innerHeight - boardSize.height) / 2)
    }, boardSize, playerIsUpTop))
  }, 50)

  useEventListener('mousemove', sendCursorPosition)
  
  return cursors
}

export default useCursors