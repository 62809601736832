import React from 'react'
import Tooltip from '../../../components/Tooltip'
import useButtonConfirm from '../../../hooks/useButtonConfirm'
import t from '../../../utils/i18n'
import styles from './PlayerStatus.module.scss'

type Props = React.HTMLProps<HTMLButtonElement> & {
  type?: "button" | "submit" | "reset" | undefined
  children: string
  confirm?: boolean
}

function ActionButton({children, confirm, onClick, ...props}: Props) {
  const [confirming, handleOnClickConfirm] = useButtonConfirm(onClick)

  return (
    <Tooltip
      title={t(confirming ? 'Sure?' : children)}
      className={confirming ? styles.tooltipConfirm : ''}
    >
      <button {...props} onClick={confirm ? handleOnClickConfirm : onClick}/>
    </Tooltip>
  )
}

export default ActionButton
