import React from 'react'
import classnames from 'classnames'
import useButtonConfirm from '../../hooks/useButtonConfirm'

import styles from './Button.module.scss'
import Tooltip from '../Tooltip'
import t from '../../utils/i18n'

type Props = React.HTMLProps<HTMLButtonElement> & {
  type?: "button" | "submit" | "reset" | undefined
  small?: boolean
  narrow?: boolean
  secondary?: boolean
  loading?: boolean
  confirm?: boolean
  disabled?: boolean
  disabledText?: string
}

function Button({
  children,
  className,
  loading,
  small,
  narrow,
  secondary,
  confirm,
  disabled,
  disabledText = '',
  onClick,
  onBlur,
  ...props
}: Props) {
  const [confirming, handleOnClickConfirm] = useButtonConfirm(onClick)

  return (
    <Tooltip title={disabledText} disabled={!disabled || !disabledText} block={!narrow}>
      <button
        {...props}
        onClick={confirm ? handleOnClickConfirm : onClick}
        className={classnames(styles.button, {
          [styles.loading]: loading,
          [styles.small]: small,
          [styles.wide]: !narrow,
          [styles.secondary]: secondary,
          [styles.confirming]: confirming,
          [styles.disabled]: disabled
        }, className)}>
        {t(confirming ? 'Sure?' : children)}
      </button>
    </Tooltip>
  )
}

export default Button