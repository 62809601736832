import {KeyboardEvent} from 'react'
import {useEventListener, useBoolean} from '@umijs/hooks'

function useHoldingKey(key: number): boolean {
  const {state, setFalse, setTrue} = useBoolean()

  function keyDownHandler(event: KeyboardEvent) {
    if (state || event.keyCode !== key) return
    setTrue()
  }

  function keyUpHandler(event: KeyboardEvent) {
    if (!state || event.keyCode !== key) return
    setFalse()
  }
  
  useEventListener('keydown', keyDownHandler)
  useEventListener('keyup', keyUpHandler)

  return state
}

export default useHoldingKey