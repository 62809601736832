
import React from 'react'
import ReactDOM from 'react-dom'
import {ApolloProvider} from '@apollo/react-hooks'
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {HttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {ApolloLink, split} from 'apollo-link';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {setContext} from 'apollo-link-context';
import {getFromLocalStorage} from './hooks/useStore'

import App from './App';

const uuid = getFromLocalStorage('uuid')

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_HTTP_ENDPOINT || 'http://localhost:4000'
});

const authLink = setContext((_, {headers}: {headers: any}) => {
  return {headers: {...headers, uuid: uuid}}
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT || 'ws://localhost:4000',
  options: {
    reconnect: true,
    connectionParams: {uuid: uuid}
  }
});

const link = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.error(`[Network error]: ${JSON.stringify(networkError)}`);
    }),
    link
  ]),
  cache: new InMemoryCache()
});

// @ts-ignore
window.__APOLLO_CLIENT__ = client

ReactDOM.render(
  <ApolloProvider client={client}>
    <App/>
  </ApolloProvider>,
  document.getElementById('root'),
)
