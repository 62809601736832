import React, {useRef, useState, useLayoutEffect} from 'react'
import classnames from 'classnames'
import useStore from '../../../../hooks/useStore'
import t from '../../../../utils/i18n'
import styles from './Nickname.module.scss'

type Props = {
  changeNickname: (nickname: string) => void
}

function Nickname({changeNickname}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const ghostRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(200)
  const nickname = useStore(state => state.nickname)
  const [value, setValue] = useState(nickname)
  
  function handleSave() {
    if (!value) return setValue(nickname)
    const trimmed = value.trim()
    if (trimmed === nickname) return
    changeNickname(trimmed)
  }
  
  useLayoutEffect(() => {
    if (!ghostRef.current) return
    setWidth(Math.max(ghostRef.current.offsetWidth, 30))
  }, [value])
  
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (!inputRef.current || !ghostRef.current) return
    switch (event.key) {
      case 'Enter':
      case 'Escape':
        inputRef.current.blur()
    }
  }

  return (
    <React.Fragment>
      <input
        ref={inputRef}
        value={value}
        onChange={event => setValue(event.target.value)}
        className={styles.input}
        onBlur={handleSave}
        onKeyDown={handleKeyDown}
        maxLength={20}
        style={{width: value ? width : 200}}
        placeholder={t('Choose a nickname!')}/>
      <div
        className={classnames(styles.input, styles.ghost)}
        ref={ghostRef}>
        {value}
      </div>
    </React.Fragment>
  )
}

export default Nickname