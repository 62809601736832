import React from 'react'

type Props = {
  color: string
}

function HandIcon({color}: Props) {
  return (
    <svg viewBox="0 0 100 100" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="m7.607925,40.423143l4.309179,-4.671831l5.354061,0.172712l4.14508,5.18135l6.908466,-3.281521l-7.253889,-8.808294l1.208982,-3.10881l-5.699485,-5.18135l3.972368,-10.017276l6.563043,0l4.490503,5.526773l5.354061,-1.03627l3.454233,-9.844564l5.872196,0l5.18135,5.18135l10.017276,0l5.008638,5.18135l2.07254,5.354061l9.153718,0.172712l3.10881,4.490503l0.863558,20.379975l-5.008638,5.872196l-0.172712,5.354061l-4.835926,3.10881l0,5.526773l-4.663215,5.008638l-0.690847,9.844564l-5.526773,0.172712l-3.454233,-3.972368l-6.390331,3.10881l-18.82557,0.863558l-0.518135,-5.699485l-5.18135,-3.454233l-3.626945,-6.21762l-6.390331,-4.14508l0.518135,-6.044908l-5.354061,-4.317791l-3.972368,-4.490503l0.008613,-6.209003z"/>
      <rect height="5" width="5" x="64.367089" y="73.592612"/><rect height="5" width="5" x="64.367089" y="78.607595"/>
      <rect height="5" width="5" x="59.367089" y="78.607595"/><rect height="5" width="5" x="54.367089" y="73.703955"/>
      <rect height="5" width="5" x="49.367089" y="78.607595"/><rect height="5" width="5" x="44.367089" y="78.607595"/>
      <rect height="5" width="5" x="39.367089" y="78.607595"/><rect height="5" width="5" x="34.367089" y="78.607595"/>
      <rect height="5" width="5" x="29.367089" y="73.655915"/><rect height="5" width="5" x="29.367089" y="78.607595"/>
      <rect height="5" width="5" y="33.495631" x="79.531224"/><rect height="5" width="5" y="23.495631" x="79.531224"/>
      <rect height="5" width="5" y="28.495631" x="79.531224"/><rect height="5" width="5" y="38.495631" x="79.531224"/>
      <rect height="5" width="5" y="48.495631" x="74.531224"/><rect height="5" width="5" y="53.495631" x="74.531224"/>
      <rect height="5" width="5" y="43.495631" x="79.531224"/><rect height="5" width="5" y="18.495631" x="74.531224"/>
      <rect height="5" width="5" y="63.495631" x="69.531224"/><rect height="5" width="5" y="58.495631" x="69.531224"/>
      <rect height="5" width="5" y="18.495631" x="69.531224"/><rect height="5" width="5" y="68.495631" x="64.531224"/>
      <rect height="5" width="5" y="33.495631" x="64.531224"/><rect height="5" width="5" y="23.495631" x="64.531224"/>
      <rect height="5" width="5" y="28.495631" x="64.531224"/><rect height="5" width="5" y="18.495631" x="64.531224"/>
      <rect height="5" width="5" y="13.495631" x="64.531224"/><rect height="5" width="5" y="8.495631" x="59.531224"/>
      <rect height="5" width="5" y="8.495631" x="54.531224"/><rect height="5" width="5" y="23.495631" x="49.531224"/>
      <rect height="5" width="5" y="28.495631" x="49.531224"/><rect height="5" width="5" y="18.495631" x="49.531224"/>
      <rect height="5" width="5" y="8.495631" x="49.531224"/><rect height="5" width="5" y="13.495631" x="49.531224"/>
      <rect height="5" width="5" y="3.4956315" x="44.531224"/><rect height="5" width="5" y="3.4956315" x="39.531224"/>
      <rect height="5" width="5" y="23.495631" x="34.531224"/><rect height="5" width="5" y="28.495631" x="34.531224"/>
      <rect height="5" width="5" y="18.495631" x="34.531224"/><rect height="5" width="5" y="8.495631" x="34.531224"/>
      <rect height="5" width="5" y="13.495631" x="34.531224"/><rect height="5" width="5" y="18.495631" x="29.531224"/>
      <rect height="5" width="5" y="13.495631" x="29.531224"/><rect height="5" width="5" y="68.495631" x="24.531224"/>
      <rect height="5" width="5" y="33.495631" x="24.531224"/><rect height="5" width="5" y="43.495631" x="24.531224"/>
      <rect height="5" width="5" y="38.495631" x="24.531224"/><rect height="5" width="5" y="8.495631" x="24.531224"/>
      <rect height="5" width="5" y="63.495631" x="19.531224"/><rect height="5" width="5" y="23.495631" x="19.531224"/>
      <rect height="5" width="5" y="28.495631" x="19.531224"/><rect height="5" width="5" y="38.495631" x="19.531224"/>
      <rect height="5" width="5" y="8.495631" x="19.531224"/><rect height="5" width="5" y="58.495631" x="14.531224"/>
      <rect height="5" width="5" y="53.495631" x="14.531224"/><rect height="5" width="5" y="33.495631" x="14.531224"/>
      <rect height="5" width="5" y="18.495631" x="14.531224"/><rect height="5" width="5" y="13.495631" x="14.531224"/>
      <rect height="5" width="5" y="48.495631" x="9.531224"/><rect height="5" width="5" y="33.495631" x="9.531224"/>
      <rect height="5" width="5" y="43.495631" x="4.531224"/><rect height="5" width="5" y="38.495631" x="4.531224"/>
    </svg>
  )
}

export default HandIcon