import gql from 'graphql-tag';

export const ON_ROOM_STATE_CHANGE = gql`
  subscription roomStateChanged($id: ID!, $lang: String!){
    roomStateChanged(id: $id, lang: $lang){
      id
      code
      me {
        accepted
        isOwner
      }
      players {
        uuid
        nickname
        color
        accepted
        connected
        isOwner
        isMaster
        score
        cards {
          id
          type
          text
        }
        placedPerk1 {
          text
          type
        }
        placedPerk2 {
          text
          type
        }
        placedFlag {
          text
          type
        }
        me
      }
      game {
        turn {
          player
          target
          type
          round
        }
        lastWinner
      }
    }
  }
`

export const JOIN_ROOM = gql`
  mutation joinRoom($code: String!, $nickname: String!, $color: String!) {
    joinRoom(code: $code, nickname: $nickname, color: $color) {
      id
    }
  }
`

export const LEAVE_ROOM = gql`
  mutation leaveRoom($roomId: ID!) {
    leaveRoom(roomId: $roomId) {
      id
    }
  }
`

export const CREATE_ROOM = gql`
  mutation createRoom($nickname: String!, $color: String!) {
    createRoom(nickname: $nickname, color: $color) {
      id
      code
    }
  }
`

export const ACCEPT_PLAYER = gql`
  mutation acceptPlayer($roomId: ID!, $playerId: String!) {
    acceptPlayer(roomId: $roomId, playerId: $playerId) {
      id
    }
  }
`

export const REJECT_PLAYER = gql`
  mutation rejectPlayer($roomId: ID!, $playerId: String!) {
    rejectPlayer(roomId: $roomId, playerId: $playerId) {
      id
    }
  }
`

export const KICK_PLAYER = gql`
  mutation kickPlayer($roomId: ID!, $playerId: String!) {
    kickPlayer(roomId: $roomId, playerId: $playerId) {
      id
    }
  }
`

export const PROMOTE_PLAYER = gql`
  mutation promotePlayer($roomId: ID!, $playerId: String!) {
    promotePlayer(roomId: $roomId, playerId: $playerId) {
      id
    }
  }
`

export const CHANGE_NICKNAME = gql`
  mutation changePlayerNickname($roomId: String!, $nickname: String!) {
    changePlayerNickname(roomId: $roomId, nickname: $nickname) {
      id
    }
  }
`

export const CHANGE_COLOR = gql`
  mutation changePlayerColor($roomId: String!, $color: String!) {
    changePlayerColor(roomId: $roomId, color: $color) {
      id
    }
  }
`

export const START_GAME = gql`
  mutation startGame($roomId: ID!) {
    startGame(roomId: $roomId) {
      id
    }
  }
`

export const STOP_GAME = gql`
  mutation stopGame($roomId: ID!) {
    stopGame(roomId: $roomId) {
      id
    }
  }
`

export const PLAY_CARD = gql`
  mutation playCard($roomId: ID!, $targetId: String!, $slot: CardSlot!, $card: Int!) {
    playCard(roomId: $roomId, targetId: $targetId, slot: $slot, card: $card) {
      id
    }
  }
`

export const PICK_WINNER = gql`
  mutation pickWinner($roomId: ID!, $playerId: String!) {
    pickWinner(roomId: $roomId, playerId: $playerId) {
      id
    }
  }
`