import React from 'react'
import classnames from 'classnames'
import t from '../utils/i18n'
import PlayerBox from '../components/player/Box'
import type {PlayerType, TurnType, CursorsType} from '../types'
import styles from './Board.module.scss'

type Props = {
  players: PlayerType[]
  cursors: CursorsType
  turn: TurnType
  upper?: boolean
  boardFlipped: boolean
  lastWinner?: string
  skipZoomTip?: boolean
}

function Group({players, turn, upper, boardFlipped, cursors, lastWinner}: Props) {
  return (
    <div className={classnames(styles.playersGroup, {[styles.upper]: upper})}>
      {players.map(player => (
        <PlayerBox
          {...player}
          key={player.uuid}
          turn={turn}
          upper={upper}
          cursor={cursors[player.uuid]}
          boardFlipped={boardFlipped}
          lastWinner={lastWinner}/>
      ))}
    </div>
  )
}

function Players({players, skipZoomTip, ...props}: Props) {
  const cutAt = Math.floor(players.length / 2) || 1
  
  return (
    <div className={styles.playersContainer}>
      <Group {...props} players={players.slice(cutAt)} upper/>
      {!skipZoomTip && (
        <div className={classnames(styles.tip, {[styles.flipped]: props.boardFlipped})}>
         <strong>{t('TIP')}</strong>{t(': Hold ')}<span className={styles.key}>{t('Control')}</span>{t(' or ')}<span className={styles.key}>{t('Space')}</span>{t(' to zoom in on a card')}
        </div>
      )}
      <Group {...props} players={players.slice(0, cutAt)}/>
    </div>
  )
}

export default Players