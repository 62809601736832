import React from 'react'
import {useMutation} from '@apollo/react-hooks'

import LobbyModal from './LobbyModal'
import useStore from '../../hooks/useStore'

import {START_GAME, STOP_GAME, LEAVE_ROOM} from '../../graphql'
import type {PlayerType} from '../../types'
import {gaEvent} from '../../utils'

type Props = {
  me?: PlayerType,
  players?: PlayerType[],
  code?: string
  started: boolean
  rounds: number
}

function LobbyModalContainer({players, me, code, started, rounds}: Props) {
  const roomId = useStore(state => state.roomId)
  const skipTooltip = useStore(state => state.skipCustomizationTooltip)
  const setState = useStore(state => state.setState)

  const [startGame] = useMutation(START_GAME)
  const [stopGame] = useMutation(STOP_GAME)
  const [leaveRoom] = useMutation(LEAVE_ROOM)

  function handleStartGame() {
    startGame({variables: {roomId}})
    gaEvent('game_start')
  }
  
  function handleStopGame() {
    stopGame({variables: {roomId}})
    gaEvent('game_stop', {rounds})
  }

  function handleLeaveRoom() {
    gaEvent('room_leave', {owner: !!me?.isOwner})
    if (started && players && players.length >= 3) gaEvent('game_stop', {rounds})
    if (players?.length === 1) gaEvent('room_delete')
    leaveRoom({variables: {roomId}})
  }
  
  function handleHideTooltip() {
    if (skipTooltip) return
    setState({skipCustomizationTooltip: true})
  }
  
  if (!code || !me || !players) {
    return null
  }
  
  return (
    <LobbyModal
      code={code}
      players={players}
      me={me}
      started={started}
      startGame={handleStartGame}
      stopGame={handleStopGame}
      leaveRoom={handleLeaveRoom}
      hideTooltip={handleHideTooltip}
      tooltipIsHidden={skipTooltip}/>
  )
}

export default LobbyModalContainer