import React, {useCallback} from 'react'
import {useMutation} from '@apollo/react-hooks'

import RoomModal from './RoomModal'
import useStore from '../../hooks/useStore'

import {JOIN_ROOM, CREATE_ROOM} from '../../graphql'
import {gaEvent} from '../../utils'

function RoomModalContainer() {
  const {nickname, color, setState} = useStore()

  const [create, createMutation] = useMutation(CREATE_ROOM, {
    update(_, {data: {createRoom: {id}}}) {
      setState({roomId: id})
    }
  })

  const [join, joinMutation] = useMutation(JOIN_ROOM, {
    update(_, {data: {joinRoom: {id}}}) {
      setState({roomId: id})
    }
  })

  const handleJoin = useCallback(async (code: string) => {
    try {
      await join({variables: {code, nickname, color}})
      gaEvent('room_join_request')
    } catch (err) {
      const roomIsFull = err.message.includes('full')
      setState({toastMessage: roomIsFull ? "Room is full!" : "That room doesn't exist!"})
      gaEvent(roomIsFull ? 'room_join_full' : 'room_join_inexistent')
    }
  }, [setState, join, nickname, color])

  const handleCreate = useCallback(() => {
    create({variables: {nickname, color}})
    gaEvent('room_create')
  }, [create, nickname, color])

  return (
    <RoomModal
      join={handleJoin}
      create={handleCreate}
      loading={createMutation.loading || joinMutation.loading}/>
  )
}

export default RoomModalContainer