import {store} from '../hooks/useStore'
import translations from '../translations.json'
import {LanguagesKeys} from '../types'

type TranslationsMap = {
  [text: string]: {
    [lang: string]: string
  }
}

let currentLang: LanguagesKeys = store.getState().lang

store.subscribe((lang: LanguagesKeys | null) => {
  if (!lang) return
  currentLang = lang
}, state => state.lang)

export function translate(text: any) {
  if (!text) {
    return ''
  }

  if (typeof text !== 'string') {
    console.warn('Translation attempted on non-string content, it will be ignored')
    return text
  }

  if (currentLang === translations._defaultLanguage) {
    return text
  }

  const translation = (translations as unknown as TranslationsMap)[text]?.[currentLang]
  if (!translation) {
    console.warn(`Missing translation for "${text}"`)
    return text
  }

  return translation
}

export default translate