import React from 'react'
import classnames from 'classnames'
import useStore from '../../hooks/useStore'
import t from '../../utils/i18n'
import {gaEvent} from '../../utils'

import styles from './FixedButtons.module.scss'

type Props = {
  playing: boolean
}

function FixedButtons({ playing }: Props) {
  const setState = useStore(state => state.setState)
  const skipTutorial = useStore(state => state.skipTutorial)

  const email = t('hello@redflags.fun')
  
  function handleOpenTutorial() {
    setState({skipTutorial: false})
    gaEvent('tutorial_open')
  }

  return (
    <div className={styles.container}>
      {playing && skipTutorial && (
        <button
          onClick={handleOpenTutorial}
          className={classnames(styles.button, styles.tutorial)}>
          {t('Show tutorial')}
        </button>
      )}
      <a
        href={`mailto:${email}`}
        onClick={() => gaEvent('mailing_intent')}
        className={classnames(styles.button, styles.email)}>
        {email}
      </a>
    </div>
  )
}

export default FixedButtons
