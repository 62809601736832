import React from 'react'
import styles from './Cursor.module.scss'
import HandIcon from './HandIcon'
import HandGrabbingIcon from './HandGrabbingIcon'
import {CursorType} from '../../types'
import Card from '../Card'

function Cursor({color, x, y, grabbing, holdingCard}: CursorType) {
  const Icon = grabbing ? HandGrabbingIcon : HandIcon
  return (
    <span
      className={styles.pointer}
      style={{top: y - 10, left: x - 10}}>
      <Icon color={color}/>
      {holdingCard !== null && (
        <div className={styles.cardContainer}>
          <Card id={holdingCard.id} type={holdingCard.type} text={holdingCard.text}/>
        </div>
      )}
    </span>
  )
}

export default Cursor