import React from 'react'
import classnames from 'classnames'
import t from '../../../utils/i18n'
import styles from './Info.module.scss'
import type {PlayerType} from '../../../types'

function PlayerInfo({
  color,
  accepted,
  connected,
  nickname,
  itsTurn,
  isMaster,
  me,
}: Partial<PlayerType> & {itsTurn?: boolean}) {
  return (
    <div className={styles.container}>
      <span
        className={classnames(styles.color, {[styles.pending]: !accepted, [styles.disconnected]: !connected})}
        style={{backgroundColor: accepted ? color : 'transparent'}}
      />
      <p className={classnames(styles.nickname, {[styles.disconnected]: !connected})}>{nickname}</p>
      {me && <span className={styles.me}>{t('(you)')}</span>}
      {isMaster && <span role='img' aria-label='Monkey see no evil' className={styles.isMaster}>🙈</span>}
      {itsTurn && <span role='img' aria-label='Raised hand' className={styles.itsTurn}>🤚</span>}
      {!connected && <span className={styles.disconnectedIcon}/>}
    </div>
  )
}

export default PlayerInfo