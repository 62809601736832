import React, {useMemo} from 'react'
import classnames from 'classnames'

import Modal from '../../components/Modal'
import Drawer from '../../components/Drawer'
import Button from '../../components/Button'
import Tooltip from '../../components/Tooltip'
import Players from './Players'
import PlayerCustomization from '../../components/player/Customization'
import styles from './LobbyModal.module.scss'
import {copyToClipboard} from '../../utils'
import {PlayerType} from '../../types'
import t from '../../utils/i18n'

type Props = {
  code: string
  me: PlayerType
  players: PlayerType[]
  started: boolean
  startGame: () => void
  stopGame: () => void
  leaveRoom: () => void
  hideTooltip: () => void
  tooltipIsHidden: boolean
}

function LobbyModal({code, players, me, started, startGame, stopGame, leaveRoom, hideTooltip, tooltipIsHidden}: Props) {
  const [needPlayers, playersNeeded] = useMemo(() => {
    const acceptedPlayers = players.reduce((accum, player) => {
      if (player.accepted) accum++
      return accum
    }, 0)
    return [acceptedPlayers < 3, 3 - acceptedPlayers]
  }, [players])
  const roomIsFull = players.length >= 6
  const drawerMode = me.accepted && started

  const content = (
    <React.Fragment>
      {me.isOwner && (
        <React.Fragment>
          <p className={styles.title}>{t(roomIsFull ? "Room is now full!" : "Give this code to your friends so they can join!")}</p>
          <div className={classnames(styles.codeContainer, {[styles.full]: roomIsFull})}>
            <p className={styles.code}>{code}</p>
            <button
              className={styles.copy}
              onClick={() => copyToClipboard(code)}/>
          </div>
        </React.Fragment>
      )}
      <Players players={players} me={me} started={started}/>
      {me.isOwner && !started && (
        <Button
          onClick={startGame}
          disabledText={needPlayers ? `You need to recruit at least ${playersNeeded} more player${playersNeeded !== 1 ? 's' : ''}` : undefined}
          disabled={needPlayers}>
          START GAME
        </Button>
      )}
      {me.isOwner && started && (
        <Button onClick={stopGame} confirm>
          STOP GAME
        </Button>
      )}
      <Button onClick={leaveRoom} small secondary confirm>Leave room</Button>
    </React.Fragment>
  )
  
  const header = (
    <Tooltip
      title="You can change nickname, color and language at any time!"
      disabled={tooltipIsHidden || drawerMode}
      persistent
      block>
      <div onClick={hideTooltip} className={styles.customizationWrapper}>
        <PlayerCustomization/>
      </div>
    </Tooltip>
  )
  
  const Container = drawerMode ? Drawer : Modal

  return <Container header={header}>{content}</Container>
}

export default LobbyModal