import {PlayerType, TurnTypeEnum, CardSlotEnum, CardTypeEnum} from "../types";

export function copyToClipboard(str: string) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function isPlayerUpTop(players: PlayerType[]) {
  const playerIndex = players.findIndex(player => player.me)
  if (!playerIndex || players.length <= 1) return false
  return playerIndex >= Math.floor(players.length / 2)
}

export function getRandomArrayItem<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]
}

export function turnAndSlotTypeMatches(turnType: TurnTypeEnum, slotType: CardSlotEnum) {
  return (
    turnType === TurnTypeEnum.Flags && slotType === CardSlotEnum.Flag
  ) || (
    turnType === TurnTypeEnum.Perks && (slotType === CardSlotEnum.Perk1 || slotType === CardSlotEnum.Perk2)
  )
}

export function turnAndCardTypeMatches(turnType: TurnTypeEnum, cardType: CardTypeEnum) {
  return (
    turnType === TurnTypeEnum.Flags && cardType === CardTypeEnum.Flag
  ) || (
    turnType === TurnTypeEnum.Perks && cardType === CardTypeEnum.Perk
  )
}

export function getLangFromBrowser() {
  if (navigator.language.startsWith('en')) {
    return 'en-us'
  }
  if (navigator.language.startsWith('es')) {
    return 'es-ar'
  }
  return ''
}

export function gaEvent(event: string, params?: Gtag.CustomParams) {
  if (!window.gtag) return
  window.gtag('event', event, params)
}
