import React, {useRef, useEffect, useMemo} from 'react'
import classnames from 'classnames'
import useStore from '../../hooks/useStore'
import type {CardType} from '../../types'
import styles from './Card.module.scss'
import {usePrevious} from '@umijs/hooks'

type Props = CardType & {
  index?: number
  holding?: boolean
  placed?: boolean
  rotated?: boolean
  held?: boolean
  highlighted?: boolean
}

function Card({id, type, text, placed, rotated, holding, held, highlighted, index}: Props) {
  const setState = useStore(state => state.setState)
  const prevHolding = usePrevious(holding)
  const cardBeingPlaced = useStore(state => state.cardBeingPlaced)
  const cardReleasedAt = useStore(state => state.cardReleasedAt)
  const parsedText = useMemo(() => text.split('\\n'), [text])
  const cardRef = useRef<HTMLDivElement>(null)

  const flipped = text === 'HIDDEN_CARD'
  
  function setCardPopup() {
    if (flipped) return
    setState({cardPopup: {id, type, text}})
  }
  
  function removeCardPopup() {
    setState({cardPopup: null})
  }
  
  function holdCard() {
    if (id === -1 || index === undefined) return
    setState({holdingCard: {index, text, type, id}})
  }
  
  useEffect(function cardReturnAnimation() {
    const cardEl = cardRef.current
    const parentEl = cardEl?.parentElement?.parentElement
    if (cardBeingPlaced || holding || holding === prevHolding || !cardReleasedAt.x || !cardEl || !parentEl) return
    
    const {top: cardTop, left: cardLeft, width, height} = cardEl.getBoundingClientRect()
    const {y: cursorTop, x: cursorLeft} = cardReleasedAt

    let topOffset = (cardTop > cursorTop ? 0 - (cardTop - cursorTop) : cursorTop - cardTop) - height / 2
    let leftOffset = (cardLeft > cursorLeft ? 0 - (cardLeft - cursorLeft) : cursorLeft - cardLeft) - width / 2
    
    parentEl.style.transform = 'translate(0) rotate(0)'
    parentEl.style.transition = 'none'
    cardEl.style.transition = ''
    cardEl.style.transform = `translate(${leftOffset}px, ${topOffset}px)`
    requestAnimationFrame(function() {
      if (!cardRef || !cardRef.current) return
      parentEl.style.transform = ''
      parentEl.style.transition = ''
      cardRef.current.style.transition = 'transform .15s ease-out'
      cardRef.current.style.transform = 'translate(0,0)'
    })
  }, [holding, cardReleasedAt, prevHolding, cardBeingPlaced])
  
  return (
    <div
      className={classnames(styles.card, styles[type], {
        [styles.holding]: holding,
        [styles.flipped]: flipped,
        [styles.placed]: placed,
        [styles.rotated]: rotated,
        [styles.highlighted]: highlighted,
        [styles.held]: held
      })}>
      <div
        onMouseDown={holdCard}
        onMouseEnter={setCardPopup}
        onMouseLeave={removeCardPopup}
        className={styles.inner}>
        <div className={styles.front} ref={cardRef}>
          {parsedText.map((line, index) => <span key={index} className={styles.line}>{line}</span>)}
        </div>
        <div className={styles.back}/>
      </div>
    </div>
  )
}

export default Card