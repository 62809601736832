import {useRef, useEffect, useCallback} from 'react'
import throttle from 'lodash.throttle'

type Callback = (...args: any[]) => any

export default function useThrottle(cb: Callback, delay: number) {
  const options = {leading: true, trailing: false}
  const cbRef = useRef<Callback>(cb)
  
  useEffect(() => {cbRef.current = cb})

  return useCallback(
    throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  )
}