import React from 'react'
import {useMutation} from '@apollo/react-hooks'
import PlayerStatus from './PlayerStatus'
import useStore from '../../../hooks/useStore'
import type {PlayerType} from '../../../types'
import {
  ACCEPT_PLAYER,
  REJECT_PLAYER,
  KICK_PLAYER,
  PROMOTE_PLAYER
} from '../../../graphql'
import {gaEvent} from '../../../utils'

type Props = PlayerType & {
  me?: boolean
  isOwner: boolean
  imOwner: boolean
  started: boolean
}

function PlayerStatusContainer(props: Props) {
  const roomId = useStore(state => state.roomId)

  const [accept] = useMutation(ACCEPT_PLAYER)
  const [reject] = useMutation(REJECT_PLAYER)
  const [kick] = useMutation(KICK_PLAYER)
  const [promote] = useMutation(PROMOTE_PLAYER)
  
  function handlePromote(playerId: string) {
    promote({variables: {roomId, playerId}})
    gaEvent('player_promote')
  }

  function handleKick(playerId: string) {
    kick({variables: {roomId, playerId}})
    gaEvent('player_kick')
  }

  function handleAccept(playerId: string) {
    accept({variables: {roomId, playerId}})
    gaEvent('player_accept', {game_ongoing: props.started})
  }

  function handleReject(playerId: string) {
    reject({variables: {roomId, playerId}})
    gaEvent('player_reject')
  }

  return (
    <PlayerStatus
      {...props}
      promote={handlePromote}
      kick={handleKick}
      accept={handleAccept}
      reject={handleReject}/>
  )
}

export default PlayerStatusContainer