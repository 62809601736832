import React, {useEffect} from 'react'
import classnames from 'classnames'
import useStore from '../../hooks/useStore'
import {useDebounceFn} from '@umijs/hooks'
import t from '../../utils/i18n'
import styles from './Toast.module.scss'

function Toast() {
  const message = useStore(state => state.toastMessage)
  const setState = useStore(state => state.setState)

  const {run: hideToastAfterDelay} = useDebounceFn(() => {
    setState({toastMessage: ''})
  }, 3000);
  
  useEffect(hideToastAfterDelay, [message])
  
  return (
    <div className={styles.container}>
      <p className={classnames(styles.message, {[styles.shown]: message})}>{t(message)}</p>
    </div>
  )
}

export default Toast
