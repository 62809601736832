import React from 'react'

import RoomModal from './RoomModal'
import useStore from '../hooks/useStore'
import LobbyModal from './LobbyModal'
import {GameType, PlayerType} from '../types'

type Props = {
  me?: PlayerType,
  players?: PlayerType[],
  code?: string
  game?: GameType
}

function Modals({me, players, code, game}: Props) {
  const roomId = useStore(state => state.roomId)
  
  if (!roomId) {
    return <RoomModal/>
  }
  
  return (
    <LobbyModal
      players={players}
      me={me}
      code={code}
      started={!!game}
      rounds={(game?.turn.round || 0) + 1}/>
  )
}

export default Modals
