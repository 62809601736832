import React, { ReactNode } from 'react'
import classnames from 'classnames'

import styles from './Modal.module.scss'

type Props = {
  children: ReactNode
  header?: ReactNode
  onBackButton?: () => void
  onCloseButton?: () => void
}

function Modal({children, onBackButton, onCloseButton, header}: Props) {
  const withHeader = onBackButton || onCloseButton || header
  return (
    <div className={styles.dimmer}>
      <div className={classnames(styles.container, {[styles.withHeader]: !!withHeader})}>
        {!!withHeader &&
          <div className={styles.header}>
            {onBackButton && <button className={styles.backButton} onClick={onBackButton}/>}
            <div className={styles.headerContent}>{header}</div>
            {onCloseButton && <button className={styles.closeButton} onClick={onCloseButton}/>}
          </div>
        }
        {children}
      </div>
    </div>
  )
}

export default Modal