import React from 'react'
import classnames from 'classnames'
import {useHover} from '@umijs/hooks'
import PlayerInfo from '../../../components/player/Info'
import Tooltip from '../../../components/Tooltip';
import ActionButton from './ActionButton';
import styles from './PlayerStatus.module.scss'
import type {PlayerType} from '../../../types'

type Props = PlayerType & {
  me?: boolean
  isOwner: boolean
  imOwner: boolean
  promote: (uuid: string) => void
  kick: (uuid: string) => void
  accept: (uuid: string) => void
  reject: (uuid: string) => void
}

function PlayerStatus({
  uuid,
  color,
  accepted,
  connected,
  nickname,
  me,
  isOwner,
  imOwner,
  score,
  promote,
  kick,
  accept,
  reject
}: Props) {
  const [hovered, ref] = useHover<HTMLLIElement>()

  return (
    <li ref={ref} className={styles.container}>
      <div>
        <PlayerInfo
          color={color}
          nickname={nickname}
          accepted={accepted}
          connected={connected}
          me={me}/>
        {(isOwner || (accepted && connected && imOwner && hovered)) && (
          <Tooltip title={isOwner ? 'Room owner' : 'Pass room ownership'}>
            <button
              className={classnames(styles.crown, {[styles.active]: isOwner})}
              onClick={() => !me && promote(uuid)}
            />
          </Tooltip>
        )}
      </div>
      <div>
        {!me && imOwner && (
          <div className={styles.actions}>
            {accepted && <ActionButton onClick={() => kick(uuid)} className={styles.kick} confirm>Kick out</ActionButton>}
            {!accepted && <ActionButton onClick={() => accept(uuid)} className={styles.accept}>Allow in</ActionButton>}
            {!accepted && <ActionButton onClick={() => reject(uuid)} className={styles.reject}>Ignore</ActionButton>}
          </div>
        )}
        {score > 0 && <span className={styles.score}>{score}</span>}
      </div>
    </li>
  )
}

export default PlayerStatus