import React from 'react'
import Customization from './Customization'
import {useMutation} from '@apollo/react-hooks'
import useStore from '../../../hooks/useStore'
import {CHANGE_NICKNAME, CHANGE_COLOR} from '../../../graphql'
import {gaEvent} from '../../../utils'

function PlayerCustomizationContainer() {
  const setState = useStore(state => state.setState)
  const roomId = useStore(state => state.roomId)
  const [changeNickname] = useMutation(CHANGE_NICKNAME)
  const [changeColor] = useMutation(CHANGE_COLOR)

  function handleChangeNickname(nickname: string) {
    setState({nickname})
    if (!roomId) return
    changeNickname({variables: {roomId, nickname}})
    gaEvent('player_change_nickname')
  }
  
  function handleChangeColor(color: string) {
    setState({color})
    if (!roomId) return
    changeColor({variables: {roomId, color}})
    gaEvent('player_change_color')
  }

  return (
    <Customization
      changeNickname={handleChangeNickname}
      changeColor={handleChangeColor}
    />
  )
}

export default PlayerCustomizationContainer