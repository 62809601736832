import React, {useCallback, useEffect, useRef} from 'react'
import classnames from 'classnames'
import confetti from 'canvas-confetti'
import Card from '../../Card'
import PlayerInfo from '../../player/Info'
import DropZone from './DropZone'
import useStore, {getFromLocalStorage} from '../../../hooks/useStore'
import {turnAndCardTypeMatches, turnAndSlotTypeMatches} from '../../../utils'
import styles from './Box.module.scss'
import {
  PlayerType,
  CardSlotEnum,
  TurnType,
  CardType,
  TurnTypeEnum,
  CursorType,
  HoldingCard,
  CardTypeEnum
} from '../../../types'
import {usePrevious} from '@umijs/hooks'

const handClasses = {
  length4: styles.four,
  length5: styles.five,
  length6: styles.six,
  length7: styles.seven
}

type Props = PlayerType & {
  turn: TurnType,
  upper?: boolean,
  boardFlipped: boolean,
  pickAsWinner: () => void,
  cursor?: CursorType
  holdingCard: HoldingCard
  lastWinner?: string
}

function PlayerBox({
  uuid,
  nickname,
  color,
  accepted,
  connected,
  cards,
  cursor,
  holdingCard,
  me,
  isMaster,
  placedPerk1,
  placedPerk2,
  placedFlag,
  turn,
  upper,
  boardFlipped,
  pickAsWinner,
  lastWinner
}: Props) {
  const perksPlaced = useStore(state => state.perksPlaced)
  const flagsPlaced = useStore(state => state.flagsPlaced)
  const cardBeingPlaced = useStore(state => state.cardBeingPlaced)
  const prevWinner = usePrevious(lastWinner)
  const boxRef = useRef<HTMLDivElement>(null)
  const myUuid = getFromLocalStorage('uuid')
  // @ts-ignore
  const handClass = handClasses[`length${cards.length}`]
  const myTurn = myUuid === turn.player
  const imTarget = uuid === turn.target
  const itsTurn = uuid === turn.player
  const pickable = turn.type === TurnTypeEnum.Pick && !isMaster && myTurn && cards.length
  const flipped = (upper && !boardFlipped) || (!upper && boardFlipped)
  
  const showConfetti = useCallback(() => {
    if (!boxRef.current) return
    const {left, width} = boxRef.current.getBoundingClientRect()
    const x = (left + width * 0.5) / window.innerWidth

    if (!flipped) {
      return confetti({
        origin: {x, y: 1.2},
        ticks: 100
      })
    }
    
    confetti({
      origin: {x, y: -0.2},
      startVelocity: 30,
      spread: 70,
      angle: -90,
      ticks: 100
    })
  }, [boxRef, flipped])
  
  useEffect(function winAnimation() {
    if (prevWinner === undefined || prevWinner === lastWinner || lastWinner !== uuid) return
    showConfetti()
  }, [prevWinner, lastWinner, showConfetti, uuid])
  
  const shouldCardBeHighlighted = useCallback((card: CardType) => {
    if (!me || !itsTurn || holdingCard || cursor?.holdingCard) return false
    if ((card.type === CardTypeEnum.Flag && flagsPlaced > 0) || (card.type === CardTypeEnum.Perk && perksPlaced > 1)) return false
    const rightType = turnAndCardTypeMatches(turn.type, card.type)
    if (!rightType) return false
    return true
  }, [itsTurn, holdingCard, cursor, flagsPlaced, perksPlaced, turn.type, me])
  
  const shouldSlotBeEnabled = useCallback((slot: CardSlotEnum) => {
    if (!myTurn) return false
    const rightSlotType = turnAndSlotTypeMatches(turn.type, slot)
    if (!rightSlotType) return false
    if (turn.type === TurnTypeEnum.Perks && !itsTurn) return false
    if (turn.type === TurnTypeEnum.Flags && !imTarget) return false
    return true
  }, [turn.type, itsTurn, imTarget, myTurn])
  
  const renderDropZone = useCallback((slot: CardSlotEnum, card?: CardType) => {
    return (
      <DropZone
        slot={slot}
        card={card}
        disabled={!shouldSlotBeEnabled(slot)}
        targetId={uuid}
        myTurn={myTurn}
        upper={flipped}/>
    )
  }, [flipped, uuid, shouldSlotBeEnabled, myTurn])
  
  return (
    <div
      ref={boxRef}
      className={classnames(styles.box, {
        [styles.me]: me,
        [styles.master]: isMaster,
        [styles.itsTurn]: uuid === turn.player,
        [styles.pickable]: pickable
      })}
      onClick={pickable ? pickAsWinner : undefined}>
      <div className={styles.dropZone}>
        {!isMaster && (
          <React.Fragment>
            {renderDropZone(CardSlotEnum.Perk1, placedPerk1)}
            {renderDropZone(CardSlotEnum.Perk2, placedPerk2)}
            {renderDropZone(CardSlotEnum.Flag, placedFlag)}
          </React.Fragment>
        )}
      </div>
      {!!cards.length && (
        <div className={classnames(styles.hand, handClass, {[styles.collapsed]: isMaster})}>
          {cards.map((card, index) => {
            const holding = (me ? holdingCard?.index : cursor?.holdingCard?.index) === index
            const beingPlaced = !!cardBeingPlaced && cardBeingPlaced.id === card.id
            return (
              <Card
                key={index}
                {...card}
                holding={holding || beingPlaced}
                highlighted={shouldCardBeHighlighted(card)}
                index={index}/>
            )
          })}
        </div>
      )}
      {<div className={classnames(styles.playerInfo, {
        [styles.flipped]: flipped
        })}>
        <PlayerInfo
          color={color}
          nickname={nickname}
          accepted={accepted}
          connected={connected}
          itsTurn={itsTurn}
          isMaster={isMaster}
          me={me}/>
      </div>}
    </div>
  )
}

export default PlayerBox