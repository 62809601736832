import React, {useEffect, useMemo} from 'react'
import Board from './Board'
import {useEventListener, useSize} from '@umijs/hooks'
import useStore from '../hooks/useStore'
import {PlayerType, TurnType} from '../types'

type Props = {
  me?: PlayerType
  players?: PlayerType[]
  turn?: TurnType
  lastWinner?: string
}

function BoardContainer({me, players = [], turn, lastWinner}: Props) {
  const setState = useStore(state => state.setState)
  const holdingCard = useStore(state => state.holdingCard)
  const [{width = 0, height = 0}, boardRef] = useSize<HTMLDivElement>()
  const acceptedPlayers = useMemo(() => players.filter(player => player.accepted), [players])
  useEventListener('mouseup', releaseCard)
  
  function releaseCard({x, y}: MouseEvent) {
    if (holdingCard === null) return
    setState({
      holdingCard: null,
      cardReleasedAt: {x, y}
    })
  }

  useEffect(function saveBoardSize() {
    setState({boardSize: {width, height}})
  }, [width, height, setState])

  if (!me || !turn) return null

  return (
    <Board players={acceptedPlayers} me={me} turn={turn} boardRef={boardRef} lastWinner={lastWinner}/>
  )
}

export default BoardContainer