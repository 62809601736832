import {useEffect, useCallback} from 'react'
import {useDebounceFn, useBoolean} from '@umijs/hooks'

function useButtonConfirm(onClick?: React.MouseEventHandler): [boolean, React.MouseEventHandler] {
  const {state: confirming, setTrue: startConfirming, setFalse: stopConfirming} = useBoolean(false)
  const {run: dismissConfirmAfterDelay} = useDebounceFn(stopConfirming, 1800);
  useEffect(dismissConfirmAfterDelay, [confirming])

  const handleOnClick = useCallback(event => {
    if (!onClick) return
    if (!confirming) return startConfirming()
    onClick(event)
  }, [onClick, confirming, startConfirming])
  
  return [confirming, handleOnClick]
}

export default useButtonConfirm
