import React from 'react'
import classnames from 'classnames'
import Card from '../../../Card'
import styles from '../Box.module.scss'
import {CardType, CardSlotEnum} from '../../../../types'

type Props = {
  disabled?: boolean
  card?: CardType | null
  holdingCard: boolean
  handleDrop: () => void
  upper?: boolean
  slot: CardSlotEnum
}

function DropZone ({disabled, card, upper, handleDrop, holdingCard, slot}: Props) {
  if (card) {
    return (
      <Card {...card} placed rotated={upper}/>
    )
  }

  return (
    <div
      onMouseUp={handleDrop}
      className={classnames(styles.card, {
        [styles.active]: !disabled,
        [styles.signaling]: !disabled && holdingCard,
        [styles.flag]: slot === CardSlotEnum.Flag 
      })}/>
  )
}

export default DropZone