import React from 'react'
import Language from './Language'
import ColorPicker from './ColorPicker'
import Nickname from './Nickname'
import styles from './Customization.module.scss'

type Props = {
  changeNickname: (nickname: string) => void
  changeColor: (color: string) => void
}

function PlayerCustomization({changeNickname, changeColor}: Props) {
  return (
    <div className={styles.container}>
      <ColorPicker changeColor={changeColor}/>
      <Nickname changeNickname={changeNickname}/>
      <Language/>
    </div>
  )
}

export default PlayerCustomization