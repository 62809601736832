export const nicknames = [
  'Marilyn Monroe',
  'Abraham Lincoln',
  'Mother Teresa',
  'John F. Kennedy',
  'Martin Luther King',
  'Nelson Mandela',
  'Winston Churchill',
  'Bill Gates',
  'Muhammad Ali',
  'Mahatma Gandhi',
  'Margaret Thatcher',
  'Charles de Gaulle',
  'Christopher Columbus',
  'George Orwell',
  'Charles Darwin',
  'Elvis Presley',
  'Albert Einstein',
  'Paul McCartney',
  'Plato',
  'Queen Elizabeth II',
  'Queen Victoria',
  'John M Keynes',
  'Mikhail Gorbachev',
  'Jawaharlal Nehru',
  'Leonardo da Vinci',
  'Louis Pasteur',
  'Leo Tolstoy',
  'Pablo Picasso',
  'Vincent Van Gogh',
  'Franklin D. Roosevelt',
  'Pope John Paul II',
  'Thomas Edison',
  'Rosa Parks',
  'Aung San Suu Kyi',
  'Lyndon Johnson',
  'Ludwig Beethoven',
  'Oprah Winfrey',
  'Indira Gandhi',
  'Eva Peron',
  'Benazir Bhutto',
  'Desmond Tutu',
  'Dalai Lama',
  'Walt Disney',
  'Neil Armstrong',
  'Peter Sellers',
  'Barack Obama',
  'Malcolm X',
  'J.K.Rowling',
  'Richard Branson',
  'Pele',
  'Angelina Jolie',
  'Jesse Owens',
  'Ernest Hemingway',
  'John Lennon',
  'Henry Ford',
  'Haile Selassie',
  'Joseph Stalin',
  'Lord Baden Powell',
  'Michael Jordon',
  'George Bush jnr',
  'V.Lenin',
  'Ingrid Bergman',
  'Fidel Castro',
  'Oscar Wilde',
  'Coco Chanel',
  'Pope Francis',
  'Amelia Earhart',
  'Adolf Hitler',
  'Sting',
  'Mary Magdalene',
  'Alfred Hitchcock',
  'Michael Jackson',
  'Madonna',
  'Mata Hari',
  'Cleopatra',
  'Grace Kelly',
  'Steve Jobs',
  'Ronald Reagan',
  'Lionel Messi',
  'Babe Ruth',
  'Bob Geldof',
  'Leon Trotsky',
  'Roger Federer',
  'Sigmund Freud',
  'Woodrow Wilson',
  'Mao Zedong',
  'Katherine Hepburn',
  'Audrey Hepburn',
  'David Beckham',
  'Tiger Woods',
  'Usain Bolt',
  'Carl Lewis',
  'Prince Charles',
  'Jacqueline Kennedy Onassis',
  'C.S. Lewis',
  'Billie Holiday',
  'J.R.R. Tolkien',
  'Tom Cruise',
  'Billie Jean King',
  'Anne Frank',
  'Simon Bolivar',
  'Marie Antoinette',
  'Christiano Ronaldo',
  'Emmeline Pankhurst ',
  'Emile Zatopek',
  'Lech Walesa',
  'Julie Andrews',
  'Florence Nightingale',
  'Marie Curie',
  'Stephen Hawking',
  'Tim Berners Lee',
  'Lance Armstrong',
  'Shakira',
  'Jon Stewart',
  'Wright Brothers',
  'Roman Abramovich',
  'Rupert Murdoch',
  'Al Gore',
  'Sacha Baron Cohen',
  'George Clooney',
  'Paul Krugman',
  'Jimmy Wales',
  'Brad Pitt',
  'Kylie Minogue',
  'Malala Yousafzai',
  'Stephen King'
]

export const colors = [
  '#ff7b7b',
  '#ffa77c',
  '#ffe088',
  '#d3ff8d',
  '#75cf8d',
  '#99d4ff',
  '#ac98ff',
  '#e5b1ff',
  '#ffa3ef',
]

export const languages = {
  'en-us': 'English',
  'es-ar': 'Español (Arg)'
}